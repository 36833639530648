





















import {
  computed,
  defineComponent,
  inject,
  PropType,
  useContext,
} from "@nuxtjs/composition-api";
import { SfRadio, SfHeading } from "@storefront-ui/vue";
import type { Aggregation, AggregationOption } from "~/modules/GraphQL/types";

export default defineComponent({
  components: {
    SfRadio,
    SfHeading,
  },
  props: {
    filter: {
      type: Object as PropType<Aggregation>,
      required: true,
    },
  },
  setup() {
    const {
      app: { i18n },
    } = useContext();
    const { isFilterSelected } = inject("UseFiltersProvider");
    const selected = computed(
      () => (id: string, optVal: string) => isFilterSelected(id, optVal)
    );
    const label = (option: AggregationOption) =>
      `${option.value === "1" ? i18n.t("Yes") : i18n.t("No")}`;
    return { selected, label };
  },
});
